var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "intercom" },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("intercom.intercom-settings"),
          actionButtonIcon: "fa fa-upload",
          actionButtonDesc: _vm.$t("intercom.save-settings")
        },
        on: { action: _vm.onSave }
      }),
      _c("basic-settings", { ref: "basicSettings" }),
      _c("sip-server-settings", { staticStyle: { "margin-top": "20px" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }