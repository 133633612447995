<template>
  <el-card :shadow="true">
    <span class="header" slot="header">{{ $t('intercom.basic-settings') }}</span>

    <el-form ref="form" :model="$store.getters.intercomSettingsFormData" label-position="left" style="width: 500px" >
      <el-form-item prop="displayName" :rules="[{required: true, message: $t('intercom.type-display-name')}]" :label="$t('intercom.display-name')">
        <el-input type="text" :placeholder="$t('intercom.type-display-name')" v-model="$store.getters.intercomSettingsFormData.displayName"></el-input>
      </el-form-item>

      <el-form-item :label="$t('intercom.enable-automatic-contact-synchronization')">
        <el-switch v-model="$store.getters.intercomSettingsFormData.enableAutomaticContactSynchronization"/>
      </el-form-item>

    </el-form>
  </el-card>
</template>

<script>
  export default {
    name: "BasicSettings",
    props: {
      displayName: String
    },

    data() {
      return {
        formData: {},
        rules: {
          displayName: [
            { validator: (rule, value, callback) => {
                if (!value) {
                  return callback(new Error(this.$t('drivers.pls-type-ip-address')));
                }
              }, trigger: "blur" }
          ]
        }
      }
    },

    methods: {
      validate: function (check) {
        this.$refs.form.validate(valid => {
          check(valid)
        })
      }
    },

    created(){

    }
  }
</script>

<style scoped>
  /* @import "../../../style/intercom.css"; */
</style>