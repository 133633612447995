var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { attrs: { shadow: true } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [_vm._v(_vm._s(_vm.$t("intercom.sip-server-settings")))]
      ),
      _c(
        "el-form",
        {
          staticStyle: { width: "500px" },
          attrs: {
            model: _vm.$store.getters.intercomSettingsFormData.sipServer,
            "label-width": "180px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("intercom.sip") } },
            [
              _c("el-switch", {
                model: {
                  value:
                    _vm.$store.getters.intercomSettingsFormData.sipServer
                      .enabled,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$store.getters.intercomSettingsFormData.sipServer,
                      "enabled",
                      $$v
                    )
                  },
                  expression:
                    "$store.getters.intercomSettingsFormData.sipServer.enabled"
                }
              })
            ],
            1
          ),
          _vm.$store.getters.intercomSettingsFormData.sipServer.enabled
            ? _c(
                "div",
                [
                  _vm.isVisibleStatusAlert
                    ? _c(
                        "el-alert",
                        {
                          attrs: {
                            closable: false,
                            type: _vm.$store.getters.intercomSettingsFormData
                              .sipServer.registrationStatus
                              ? "success"
                              : "error"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "font-weight": "bolder" },
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(_vm.statusTitle))]
                          ),
                          !_vm.$store.getters.intercomSettingsFormData.sipServer
                            .registrationStatus
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "5px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$store.getters
                                        .intercomSettingsFormData.sipServer
                                        .statusMessage
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "15px" },
                      attrs: { label: _vm.$t("intercom.sip-server") }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("intercom.type-server")
                            },
                            model: {
                              value:
                                _vm.$store.getters.intercomSettingsFormData
                                  .sipServer.serverUrl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$store.getters.intercomSettingsFormData
                                    .sipServer,
                                  "serverUrl",
                                  $$v
                                )
                              },
                              expression:
                                "$store.getters.intercomSettingsFormData.sipServer.serverUrl"
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 1 } }, [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("-")
                        ])
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "5060" },
                            model: {
                              value:
                                _vm.$store.getters.intercomSettingsFormData
                                  .sipServer.port,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$store.getters.intercomSettingsFormData
                                    .sipServer,
                                  "port",
                                  $$v
                                )
                              },
                              expression:
                                "$store.getters.intercomSettingsFormData.sipServer.port"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("intercom.transport") } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("intercom.select-transport"),
                            filterable: ""
                          },
                          model: {
                            value:
                              _vm.$store.getters.intercomSettingsFormData
                                .sipServer.transportType,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$store.getters.intercomSettingsFormData
                                  .sipServer,
                                "transportType",
                                $$v
                              )
                            },
                            expression:
                              "$store.getters.intercomSettingsFormData.sipServer.transportType"
                          }
                        },
                        _vm._l(_vm.transportTypes, function(type) {
                          return _c("el-option", {
                            key: type.value,
                            attrs: { label: type.title, value: type.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("intercom.username") } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("intercom.type-username")
                        },
                        model: {
                          value:
                            _vm.$store.getters.intercomSettingsFormData
                              .sipServer.username,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$store.getters.intercomSettingsFormData
                                .sipServer,
                              "username",
                              $$v
                            )
                          },
                          expression:
                            "$store.getters.intercomSettingsFormData.sipServer.username"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("intercom.password") } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("intercom.type-password")
                        },
                        model: {
                          value:
                            _vm.$store.getters.intercomSettingsFormData
                              .sipServer.password,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$store.getters.intercomSettingsFormData
                                .sipServer,
                              "password",
                              $$v
                            )
                          },
                          expression:
                            "$store.getters.intercomSettingsFormData.sipServer.password"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }