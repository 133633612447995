var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { attrs: { shadow: true } },
    [
      _c(
        "span",
        { staticClass: "header", attrs: { slot: "header" }, slot: "header" },
        [_vm._v(_vm._s(_vm.$t("intercom.basic-settings")))]
      ),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { width: "500px" },
          attrs: {
            model: _vm.$store.getters.intercomSettingsFormData,
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "displayName",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("intercom.type-display-name")
                  }
                ],
                label: _vm.$t("intercom.display-name")
              }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("intercom.type-display-name")
                },
                model: {
                  value:
                    _vm.$store.getters.intercomSettingsFormData.displayName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$store.getters.intercomSettingsFormData,
                      "displayName",
                      $$v
                    )
                  },
                  expression:
                    "$store.getters.intercomSettingsFormData.displayName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t(
                  "intercom.enable-automatic-contact-synchronization"
                )
              }
            },
            [
              _c("el-switch", {
                model: {
                  value:
                    _vm.$store.getters.intercomSettingsFormData
                      .enableAutomaticContactSynchronization,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$store.getters.intercomSettingsFormData,
                      "enableAutomaticContactSynchronization",
                      $$v
                    )
                  },
                  expression:
                    "$store.getters.intercomSettingsFormData.enableAutomaticContactSynchronization"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }