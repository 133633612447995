<template>
  <el-card :shadow="true">
    <div class="clearfix" slot="header">{{ $t('intercom.sip-server-settings') }}</div>

    <el-form :model="$store.getters.intercomSettingsFormData.sipServer" label-width="180px" label-position="left" style="width: 500px">
      <el-form-item :label="$t('intercom.sip')">
        <el-switch v-model="$store.getters.intercomSettingsFormData.sipServer.enabled"></el-switch>
      </el-form-item>

      <div v-if="$store.getters.intercomSettingsFormData.sipServer.enabled">

          <el-alert v-if="isVisibleStatusAlert" :closable="false" :type="$store.getters.intercomSettingsFormData.sipServer.registrationStatus ? 'success' : 'error'">
            <div style="font-weight: bolder" slot="title">{{ statusTitle }}</div>
            <div v-if="!$store.getters.intercomSettingsFormData.sipServer.registrationStatus" style="margin-top: 5px">{{ $store.getters.intercomSettingsFormData.sipServer.statusMessage }}</div>
          </el-alert>

        <el-form-item :label="$t('intercom.sip-server')" style="margin-top: 15px">
        <el-col :span="18">
          <el-input type="text" :placeholder="$t('intercom.type-server')" v-model="$store.getters.intercomSettingsFormData.sipServer.serverUrl">
          </el-input>
        </el-col>
        <el-col :span="1"><div style="text-align: center">-</div></el-col>
        <el-col :span="5">
          <el-input type="text" v-model="$store.getters.intercomSettingsFormData.sipServer.port" placeholder="5060"></el-input>
        </el-col>

      </el-form-item>

      <el-form-item :label="$t('intercom.transport')">
        <el-select v-model="$store.getters.intercomSettingsFormData.sipServer.transportType" :placeholder="$t('intercom.select-transport')" filterable>
              <el-option v-for="type in transportTypes" :label="type.title" :value="type.value" :key="type.value"></el-option>
            </el-select>
      </el-form-item>

      <el-form-item :label="$t('intercom.username')">
        <el-input type="text" :placeholder="$t('intercom.type-username')" v-model="$store.getters.intercomSettingsFormData.sipServer.username">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('intercom.password')">
        <el-input type="text" :placeholder="$t('intercom.type-password')" v-model="$store.getters.intercomSettingsFormData.sipServer.password">
        </el-input>
      </el-form-item>
      </div>

    </el-form>
  </el-card>
</template>

<script>
export default {
  name: "SipServerSettings",
  data() {
    return {
      formData: {},
      transportTypes: [
        { title: "UDP", value: 0 },
        { title: "TLS", value: 1 },
        { title: "TCP", value: 2 },
      ],
      defaultSipServer: {
        enabled: false,
        serverUrl: "",
        port: 5060,
        username: "",
        password: "",
        transportType: 0,
        registrationStatus: false,
        statusMessage: "",
      },
    };
  },
  computed: {
    statusTitle: function() {
      return this.$store.getters.intercomSettingsFormData.sipServer
        .registrationStatus
        ? this.$t("intercom.registration-success")
        : this.$t("intercom.registration-fail");
    },
    isVisibleStatusAlert() {
      if (
        this.$store.getters.intercomSettingsFormData.sipServer
          .registrationStatus
      ) {
        return true;
      } else {
        return this.$store.getters.intercomSettingsFormData.sipServer
          .statusMessage;
      }
    },
  },
  watch: {
    "$store.getters.intercomSettingsFormData.sipServer.enabled": function(val) {
      console.log("enable", val);
      if (!val)
        this.$store.getters.intercomSettingsFormData.sipServer = {
          ...this.defaultSipServer,
        };
    },
  },
};
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
</style>
