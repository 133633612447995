<template>
  <div class="intercom">
    <app-navigation :title="$t('intercom.intercom-settings')" actionButtonIcon="fa fa-upload"
      :actionButtonDesc="$t('intercom.save-settings')" @action="onSave"></app-navigation>

    <basic-settings ref="basicSettings"></basic-settings>
    <sip-server-settings style="margin-top: 20px"></sip-server-settings>
  </div>
</template>

<script>
import BasicSettings from "./components/BasicSettings.vue";
import SipServerSettings from "./components/SipServerSettings.vue";
import Navigation from "@/components/Navigation.vue";
import { initializeWebSocket } from "@/utils/socket-utils"

export default {
  name: "Settings",
  components: {
    basicSettings: BasicSettings,
    sipServerSettings: SipServerSettings,
    appNavigation: Navigation,
  },

  computed: {
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    onSave() {
      this.$refs.basicSettings.validate((valid) => {
        if (valid) {
          var obj = { ...this.$store.getters.intercomSettingsFormData };
          console.log("save intercom", obj);

          this.$store
            .dispatch("saveIntercomSettings", obj)
            .then((response) => {
              this.$message({
                type: "success",
                message: this.$t(response.message),
              });
            })
            .catch((reason) => {
              this.$message({ type: "error", message: this.$t(reason) });
            });
        }
      });
    },
    async fetchData() {
      try {
        await this.$store.dispatch("getIntercomSettings");
        this.$store.commit("setIntercomSettingsFormData", {
          ...this.$store.getters.intercomSettings,
        });
      } catch (error) {
        console.log(error)
      }
    }
  },

  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  },

  destroyed() {
    this.$store.commit("setIntercomSettingsFormData", {});
  },
};
</script>

<style scoped>
@import "../../style/intercom.css";
</style>
